<template>
  <div>
    <div
      class="tabItemRCS"
      v-show="isActive"
      v-bind:class="{ active: isActiveShow }"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: { required: true },
    selected: { default: false }
  },

  data() {
    return {
      isActive: false,
      isActiveShow: false
    }
  },

  computed: {
    // href() {
    //   return '#' + this.name.toLowerCase().replace(/ /g, '-')
    // }
  },
  methods: {},
  mounted() {
    this.isActive = this.selected
  },
  watch: {
    isActive(newVal) {
      if (newVal == true) {
        setTimeout(() => {
          this.isActiveShow = true
        }, 100)
      } else {
        this.isActiveShow = false
      }
    }
  }
}
</script>
